import webLogo from "./img/kmg-kalibrierung_Web_EN.jpg";
import gteLogo from "./img/GTE_Logo_Zusatzrechts_GmbH_RGB.png";
import "./App.css";
import React, {Component, useEffect, useState} from "react";
import download from "downloadjs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import axios from "axios";

function App() {
  const [seriennummer, setSeriennummer] = useState("");
  const [passwort, setPasswort] = useState("");
  const [spinnerVisually, setSpinnerVisually] = useState("visually-hidden");
  const [downloadText, setDownloadText] = useState("Herunterladen");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(()=>{
    const meta = document.createElement('meta');
    meta.httpEquiv = 'Cache-Control';
    meta.content = 'no-cache, no-store, must-revalidate';
    document.head.appendChild(meta);
  }, [])
  async function getRequest() {
    setDownloadText("");
    setSpinnerVisually("");
    await axios({
      method: "GET",
      url:
          "https://l12982z7lh.execute-api.eu-central-1.amazonaws.com/prod/download?seriennummer=" +
          seriennummer +
          "&passwort=" +
          passwort,
      responseType: "json",
      headers: {
        'Content-Type': 'application/json'
      }
    })
        .then((res) => {
          setDownloadText(downloadText);
          setSpinnerVisually("visually-hidden");
          download(
              res.data.signedUrl,
          );
          setSeriennummer("");
          setPasswort("");
        })
        .catch((err) => {
          setDownloadText(downloadText);
          setShow(true);
          setSpinnerVisually("visually-hidden");
          setSeriennummer("");
          setPasswort("");
        });
  }

  return (
    <Container fluid id="mainContainer">
    <Image fluid id="mainImage" src={webLogo} />

      <Row id="navRow" className="justify-content-center">
        <Navbar id="navBar" expand="md">
          <Navbar.Brand>
            <Image
              src={gteLogo}
              id="gteLogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle style={{ borderColor: "white"}} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link onClick={() => { window.open("https://www.gte.de/en/impressum") }}>IMPRINT</Nav.Link>
              <Nav.Link onClick={() => { window.open("https://www.gte.de/en/datenschutz") }}>PRIVATY POLICY</Nav.Link>
              <Nav.Link onClick={() => { window.open("https://www.gte.de/en/kontakt/anfahrt_oeffnungszeiten/kontakt-messtechnik") }}>CONTACT</Nav.Link>
              <Nav.Link onClick={() => { window.open("https://kalibrierung.gte.de")}}>GERMAN</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
      <Row className="justify-content-center">
        <Card id="card" className="text-center">
          <Card.Title id="cardTitle">Download KMG calibration certificate</Card.Title>
          <Card.Text>
            You will receive the serial number and password with your calibrated
            device.
          </Card.Text>
          <InputGroup className="mb-2">
            <Form.Control
              placeholder="Serial Number"
              aria-label="Serial Number"
              aria-describedby="basic-addon1"
              value={seriennummer}
              onChange={(newText) => {
                setSeriennummer(newText.target.value);
              }}
              type="text"
              name="seriennummer"
            />
          </InputGroup>
          <InputGroup className="mb-2">
            <Form.Control
              placeholder="Password"
              aria-label="Password"
              aria-describedby="basic-addon1"
              value={passwort}
              onChange={(newText) => {
                setPasswort(newText.target.value);
              }}
              type="password"
              name="passwort"
            />
          </InputGroup>
          <Button
            style={{ backgroundColor: "rgb(0, 48, 87)" }}
            onClick={getRequest}
          >
            {downloadText}
            <Spinner
              className={spinnerVisually}
              animation="border"
              size="sm"
            />
          </Button>
        </Card>
        </Row>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            "Your calibration certificate could not be found. \n Please try again later."
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "rgb(0, 48, 87)" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default App;